import $ from 'jquery';

$(document).ready(function() {

	/*
	function setMainPaddingTop() {
		if ($(document.body).hasClass('home')) return;
		$('main').css('paddingTop', $('header').height()+20);
	}
	setTimeout(function () {
		setMainPaddingTop()
	}, 100);
	setMainPaddingTop();
	$(window).resize(setMainPaddingTop);

	function setMainPaddingTopHome() {
		$('.home main').css('paddingTop', $('header .top_bar').height()+9);
	}
	setTimeout(function () {
		setMainPaddingTopHome()
	}, 100);
	setMainPaddingTopHome();
	$(window).resize(setMainPaddingTopHome);
	 */

	$(window).scroll(function() {
		var sticky = $('header'),
		scroll = $(window).scrollTop();
	
		if (scroll >= 100) { 
			sticky.addClass('fixed'); 
		} else { 
			sticky.removeClass('fixed');
			
		}
	});

	//HAMBURGER
	
	$("#menu-toggle").click(function(e) {
	    e.preventDefault();
	    $("header").toggleClass("toggled");
	    $("#sidebar-wrapper").toggleClass("toggled");
	});
	
	$("#sidebar-wrapper .close").click(function(e) {
	    e.preventDefault();
	    $("header").removeClass("toggled");
		$("#sidebar-wrapper").toggleClass("toggled");
	});

	function isMobile() {
		return $(window).width() <= 1023;
	}
	$('.dropdown-menu > li > .dropdown-menu').parent().addClass('dropdown-submenu').find(' > .dropdown-item').attr('href', 'javascript:;').addClass('dropdown-toggle');
	$('.sidebar-nav .dropdown-menu > li > .dropdown-menu').parent().addClass('dropdown-submenu').find(' > .dropdown-item').addClass('dropdown-toggle');

	$('.menu-item').on("click", function(e) {
		$('.dropdown-submenu.show').removeClass('show').find('.dropdown-menu, .dropdown-toggle').removeClass('show');
		$('.dropdown-submenu > a.dropdown-toggle[aria-expanded="true"]').attr('aria-expanded', 'false');
	});

	$('.dropdown-submenu > a.dropdown-toggle').on("click", function(e) {
		e.preventDefault();
		var parentLi = $(this).parent();
		var dropdownLink = $(this);

		if (parentLi.hasClass('show')) {
			parentLi.removeClass('show');
			parentLi.find('.dropdown-menu, .dropdown-toggle').removeClass('show');
			dropdownLink.attr('aria-expanded', 'false');
		} else {
			// Fermez tous les autres sous-sous-menus ouverts du même parent
			var siblingLinks = parentLi.siblings('.dropdown-submenu.show').removeClass('show').find('.dropdown-menu, .dropdown-toggle').removeClass('show');
			siblingLinks.parent().find('> a.dropdown-toggle[aria-expanded="true"]').attr('aria-expanded', 'false');

			parentLi.addClass('show');
			parentLi.find('.dropdown-menu, .dropdown-toggle').addClass('show');
			dropdownLink.attr('aria-expanded', 'true');
		}

		e.stopPropagation();
	});

	$('a.nav-link.dropdown-toggle').on('click', function(e) {
		if (isMobile()) {
			var parentLi = $(this).parent();

			if (!parentLi.hasClass('show')) {
				e.preventDefault();
			}
		} else {
			location.href = this.href;
		}
	});

	$(".top_bar .close").click(function(e) {
		e.preventDefault();
		$(".top_bar").slideUp(300);
		$('.home main').css('paddingTop', 0);
	});

	$(".search_mobile").click(function(e) {
		e.preventDefault();
		$(".search_bar").fadeToggle(300);
	});

	$(".search_bar .close").click(function(e) {
		e.preventDefault();
		$(".search_bar").fadeToggle(500);
	});

	$(".dropdown-submenu .dropdown-toggle").click(function(e) {
		e.preventDefault();
		$(this).toggleClass('changeArrow');
	});

	//$('.carousel').carousel();
	$('.carousel-item:first-child').addClass('active');

	$(".open_booxi").click(function() {
		booxiController.open('all');
	});

});