import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
	if (!isLocalStorageAvailable()) {
		// Si le localStorage n'est pas disponible, afficher la bannière par défaut
		$('.operation').removeClass('d-none');
		return;
	}

	const banner = $('.operation');
	const lastClose = window.localStorage.getItem('banner-timestamp');

	const now = Math.floor(Date.now() / 1000); // Horodatage actuel en secondes
	const oneDayInSeconds = 24 * 60 * 60; // Nombre de secondes dans une journée

	const lastCloseTimestamp = parseInt(lastClose, 10);

	if (
		lastClose === null ||
		isNaN(lastCloseTimestamp) ||
		lastCloseTimestamp + oneDayInSeconds < now
	) {
		$(banner).removeClass('d-none');
	}

	$('.close', banner).click(() => {
		const now = Math.floor(Date.now() / 1000);
		window.localStorage.setItem('banner-timestamp', now.toString());
		$(banner).addClass('d-none'); // Optionnel : cacher la bannière immédiatement
	});
});

function isLocalStorageAvailable() {
	try {
		const testKey = '__test__';
		window.localStorage.setItem(testKey, testKey);
		window.localStorage.removeItem(testKey);
		return true;
	} catch (e) {
		return false;
	}
}

