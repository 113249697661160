import $ from 'jquery';

$(document).ready(function() {

    $('h3[data-toggle="collapse"]').toggleClass('collapsed');
    $('.title_accordion[data-toggle="collapse"]').toggleClass('collapsed');

    function checkOffset() {
        if($('.operation').offset().top + $('.operation').height() >= $('footer').offset().top - 10)
            $('.operation').css('position', 'absolute');
        if($(document).scrollTop() + window.innerHeight < $('footer').offset().top)
            $('.operation').css('position', 'fixed'); // restore when you scroll up
    }
    $(document).scroll(function() {
        checkOffset();
    });

    $(".operation .close").click(function(e) {
        e.preventDefault();
        $(".operation").fadeOut(300);
    });

    $(".magasins_operations .close").click(function(e) {
        e.preventDefault();
        $(".magasins_operations").fadeOut(300);
    });

    $('.read_more').click(function () {
        $('.category_description').toggleClass('open');
        $(this).toggleClass('open');
        if ( $(this).hasClass('open') ) {
            $(this).text("> Voir moins");
        } else {
            $(this).text("> Voir plus");
        }
    });

    var summary = '.services_sommaire a[href*="#"]:not([href="#"])';

    $(summary).click(function () {
        var target = $(this.hash);
        $('html,body').stop().animate({
            scrollTop: target.offset().top - 120
        }, 'linear');
    });
    if (location.hash) {
        var id = $(location.hash);
    }
    $(window).on('load', function () {
        if (location.hash) {
            $('html,body').animate({scrollTop: id.offset().top - 120}, 'linear')
        }
    });

    var ancre_fiche = '.reassurance p.black > a';

    $(ancre_fiche).click(function () {
        var target = $(this.hash);
        $('html,body').stop().animate({
            scrollTop: target.offset().top - 150
        }, 'linear');
    });
    if (location.hash) {
        var id_two = $(location.hash);
    }
    $(window).on('load', function () {
        if (location.hash) {
            $('html,body').animate({scrollTop: id_two.offset().top - 120}, 'linear')
        }
    });

    $('input[name="checkbox_all_filters"]').click(function () {
        $('.all_filter_box').toggleClass('open');
    });

    $(".all_filter_box .close").click(function(e) {
        e.preventDefault();
        $('.all_filter_box').removeClass('open');
    });

    $(".show_more").click(function(e) {
        e.preventDefault();
        $('.show_off').toggleClass('open');
        $('.button_show_more').toggleClass('active_button');
        if ( $('.button_show_more').hasClass('active_button') ) {
            $(this).text('voir moins');
        } else {
            $(this).text('voir plus');
        }
    });

    var sourceSwap = function () {
        var $this = $(this);
        var newSource = $this.data('alt-src');
        $this.data('alt-src', $this.attr('src'));
        $this.attr('src', newSource);
    }
    $('img.xyz').hover(sourceSwap, sourceSwap);

    /*
    $("li[data-sf-field-input-type=\"checkbox\"]").click(function() {
        $(this).find('ul').fadeToggle(200);
    });
    */

    $('.carouselCategory').carousel({
        interval: false,
	    wrap: true
    });
    $(document).on('mouseleave', '.carouselCategory', function() {
        $(this).carousel('pause');
    });

    //$('.dgwt-wcas-product-details-readmore').text('Lire la suite');

    $( ".dgwt-wcas-product-details-readmore" ).text( "Lire la suite" );

	$('#scroll-forward').click(function() {
		$('#scrollable-content').animate({
			scrollLeft: '+=100'
		}, 500);
	});

	$('#scroll-backward').click(function() {
		$('#scrollable-content').animate({
			scrollLeft: '-=100'
		}, 500);
	});

    //$('.collapse').collapse()


});