import $ from 'jquery';
import moment from 'moment';

const banner = $('.operation');
const lastClose = window.localStorage.getItem('banner-timestamp');

if (lastClose === null || moment(lastClose, 'X').add(1, 'day').isBefore(moment()))
    $(banner).removeClass('d-none');

$('.close', banner).click(() => {
    window.localStorage.setItem('banner-timestamp', moment().format('X'))
});