window.WP_Grid_Builder && WP_Grid_Builder.on('init', function(wpgb) {
	wpgb.facets.on('map.afterInit', function(instance) {

		// Lorsque la carte est cliquée
		instance.map.addListener('click', function() {
			// Fermer tous les popups ouverts
			if (instance.infowindow && instance.infowindow.close) {
				instance.infowindow.close();
			}
		});

		// Votre code existant...
		document.querySelector('.store_locator').addEventListener('click', function(e) {
			if (e.target.classList.contains('store-title')) {
				const postId = parseInt(e.target.dataset.postId);
				const marker = instance.markers[postId];
				if (marker) {
					new google.maps.event.trigger(marker, 'click');
					instance.map.setCenter(marker.getPosition());
					instance.map.setZoom(15);
				}
			}
		});
	});

});

