import $ from "jquery";

$('.gridbuilder .wpgb-facet').on('click', function() {
    $('.gridbuilder .wpgb-facet').not(this).find('fieldset').hide();
    $(this).find('fieldset').fadeToggle(200);
});

$('.filter_box .wpgb-facet').on('click', function() {
    $('.filter_box .wpgb-facet').not(this).find('fieldset').hide();
    $(this).find('fieldset').fadeToggle(200);
});
